<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>专业管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="专业列表" icon="ios-contacts">
            <normal-list
              :columnsFormatData="columnsFormatData"
              :askData="askData"
              :refreshData="update_count"
              main_width="1260px"
            ></normal-list>
          </TabPane>
          <Button @click="openModal('addMajor')" slot="extra" style="margin-right:5px;">新增专业</Button>
        </Tabs>
      </div>
      <Modal v-model="modalSwicth.addMajor" :width="800" :mask-closable="false" @on-cancel="closeModal('addMajor')">
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box"/>
        <span>新增专业</span>
      </p>
      <i class="ivu-icon ivu-icon-ios-close" slot="close" @click="closeModal('addMajor')"></i>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
              <Form ref="formMajor" :model="formItem" :rules="ruleValidate" :label-width="250">
              <FormItem label="专业名称：" prop="name" style="width:650px">
                <Input v-model="formItem.name" placeholder />
              </FormItem>
            </Form>
            </div>
        </div>
        <div slot="footer">
        <Button @click="handleSubmit('formMajor')" :loading="loadingStatus" size="large">{{loadingStatus ? 'Uploading' : '确认提交' }}</Button>
        <Button size="large" @click="closeModal('addMajor')" class="cancel-button">取消</Button>
      </div>
    </Modal>
    </div>
  </div>
</template>
<script>
import normalList from "@/components/Common/NormalList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));

function formItemFac(){
  return {
    id:0,
    name:""
  };
}

export default {
  name: "majorManage",
  components: {
    normalList,
  },
  props: {},
  data() {
    return {
      askData: "majorList",
      update_count: 1,
      columnsFormatData: [
        {
          title: '专业名称',
          key: 'name',
          align: "center",
        }, 
        {
          title: '创建时间',
          width:180,
          align: "center",
          key:'create_time'
        }, 
        {
          title: '创建人',
          width:150,
          align: "center",
          key:'creator'
        },    
        {
          title: "操作",
          align: "center",
          width: 300,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small"
                  },
                  style: { marginRight: "10px",color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    }
                  }
                },
                "删除"
              ),
            ]);
          }
        }
      ],
      modalSwicth:{
        addMajor:false,
      },
      formItem: formItemFac(),
      ruleValidate: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入标准编号！",
            trigger: "blur"
          }
        ],
      },
      loadingStatus: false,
    };
  },
  watch: {},
  computed: {
    
  },
  created() {
  },
  methods: {
    openModal(value){
      this.modalSwicth[value] = true;
    },
    closeModal(value){
      this.modalSwicth[value] = false;
    },
    // getStaticOption(){
    //   this.$axios
    //       .post(this.$url + "/ask_static_options", {
    //         userToken: userToken,
    //         askData:'standardManage'
    //       })
    //       .then(response => {
    //         if (response.data.stat == "ok") {
    //           this.standardOptions = response.data.res_record;
    //         } else {
    //           this.$Massage.error("获取选项数据失败，网络存在问题！");
    //         }
    //       });
    // },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content: "<p>" + "专业：" + value.name+"</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {}
      });
    },
    recordFormatDel(id){
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", 'delMajor');
      form.append("id", id);
      let config = {
            headers: { "Content-Type": "multipart/form-data" }
          };
      this.$axios
        .post(this.$url + "/archive_manage", form, config)
        .then(response => {
          if(response.data.stat==='ok'){
          this.$Message.success("删除成功！");
          this.update_count ++;
          }
        })
        .catch(error => {
          this.$Message.warning(error+"网络错误！操作失败。");
        });
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", 'addMajor');
          form.append("info", JSON.stringify(this.formItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" }
          };
          this.$axios
            .post(this.$url + "/archive_manage", form, config)
            .then(response => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("添加成功！");
                this.update_count++;
                this.modalSwicth.addMajor = false;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch(error => {
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    }
  }
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}
</style>